import React from "react";
import { graphql } from "gatsby";

import {
	Container,
	Card,
	CardHeader,
	CardActionArea,
	Grid
} from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";

import Img from "gatsby-image";

import Layout from "../../layout";
import Seo from "../../seo";

const BlogPage = ({
	location,
	data: {
		allBlogPost: { nodes: posts }
	}
}) => (
	<Layout location={location}>
		<Seo
			title="Blog"
			description="Everything you need to know about scaling and running jobs in the cloud or your local infrastructure. Company updates & Technology trends."
		/>

		<Container>
			<Grid container spacing={3}>
				{posts.map((post, i) => (
					<Grid key={post.slug} item xs={12} md={i == 0 ? 12 : 6}>
						<PostCard {...post} />
					</Grid>
				))}
			</Grid>
		</Container>
	</Layout>
);

const PostCard = ({ slug, cover, date, dateFormatted, title }) => (
	<Card>
		<CardActionArea
			component={props => <Link {...props} underline="none" />}
			to={slug}
		>
			{cover ? (
				<Img
					fluid={cover.fluid ? cover.fluid : cover.img.fluid}
					style={{ maxHeight: 300 }}
				/>
			) : null}

			<CardHeader
				title={title}
				subheader={<time dateTime={date}>Published on {dateFormatted}</time>}
			/>
		</CardActionArea>
	</Card>
);

export default BlogPage;

export const blogQuery = graphql`
	query BlogPageQuery {
		allBlogPost(sort: { fields: date, order: DESC }) {
			nodes {
				slug
				title
				date
				dateFormatted: date(formatString: "MMMM Do, YYYY")
				cover {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	}
`;
